import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from  'react-router-dom';
import axios from "../../api/httpclient";

export class Rewards extends Component {
  state = {
    employee: [],
    columns: [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true
      },
      {
        dataField: 'lastName',
        text: 'Lastname',
        filter: textFilter(),
        sort:true
      },
      {
        dataField: 'firstName',
        text: 'Firstname',
        filter: textFilter(),
        sort:true
      },
      {
        dataField: 'email',
        text: 'Email',
        filter: textFilter(),
        sort:true
      },
      {
        dataField: 'df1',
        isDummyField: true,
        text: 'Actions',
        formatter: (cellContent, row) => {
            return (
              <Link to={{
                pathname: "/user-rewards",
                  state: { id: row.id }
              }}><svg className="MuiSvgIcon-root RaButton-smallIcon-220" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg></Link>
            );
        }
      },
      ]
  }
  componentDidMount() {
    axios.get('/users?pagination=false&order%5BlastName%5D=ASC',
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
        }
      }).then(response => {
      console.log(response.data);
      this.setState({
        employee: response.data['hydra:member']
      });
    });
  }

  render() {
    return (
      <div className="container">
        <div className="row" className="hdr">
          <h1>Customers</h1>
        </div>
        <div  style={{ marginTop: 20 }}>
          <BootstrapTable
            hover
            keyField='id'
            data={ this.state.employee }
            columns={ this.state.columns }
            filter={ filterFactory() }
            pagination={ paginationFactory() }/>
        </div>
      </div>
    )
  }
}

export default Rewards
