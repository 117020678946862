import React from "react"
import instance from "../../api/httpclient";
import { Redirect } from  'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Button, Form} from "react-bootstrap";

class OfferCreation extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        imageId: '',
        description: '',
        campaign: '',
        ctaLabel: '',
        ctaUrl: '',
        startDate: '',
        endDate: '',
        country: 'fr-FR',
        countries: ['fr-FR'],
        include: false,
        getAQuote: false,
        bookAppointment: false,
        userStatus: 1,
        detailTitle: '',
        detailDescription: '',
        offerDescription: '',
        redirection: false,
        fileInput: {},
        errors: {},
        campaigns: []
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCheck = this.handleCheck.bind(this);
      this.uploadFile = this.uploadFile.bind(this);
    }

  componentDidMount() {
      let currentComponent = this;
      instance.get('/campaigns',
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          currentComponent.setState({campaigns: response.data['hydra:member']});
          currentComponent.setState({campaign: response.data['hydra:member'][0]['@id']});
        });
  }

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'name':
        errors.name =
          value.length < 3
            ? 'Name is required.'
            : '';
        break;
      case 'description':
        errors.description =
          value.length < 3
            ? 'Description is required.'
            : '';
        break;
      case 'startDate':
        errors.startDate =
          value === null || value === ''
            ? 'Start date is required.'
            : '';
        break;
      case 'endDate':
        errors.endtDate =
          value === null || value === ''
            ? 'End date is required.'
            : '';
        break;
      default:
        break;
    }

    this.setState({errors, [name]: value}, ()=> {
      console.log(errors)
    });

    let _state = {};
    _state[event.target.name] = event.target.value;
    this.setState(_state);
  }

  validateForm(errors) {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );

    return valid;
  }

  handleCheck(event) {
    let _state = {};
    _state[event.target.name] = event.target.checked;
    this.setState(_state);
  }

  uploadFile(event) {
    let currentComponent = this;
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    let targetName = event.target.name;

    let formData = new FormData();
    formData.append('file', files[0], files[0].name)

    reader.onload = (ev) => {
      instance.post('/media_objects',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          console.log('RESPONSE', response);
          if ('fileInput' === targetName) {
            currentComponent.setState({imageId: response.data['@id']});
          } else {
            currentComponent.setState({sliderImageId: response.data['@id']});
          }
        });
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    let currentComponent = this;

    if(this.validateForm(this.state.errors)) {
      instance.post('/offers',
        {
          name: this.state.name,
          thumbnail: this.state.imageId,
          sliderImage: this.state.sliderImageId,
          description: this.state.description,
          ctaLabel: this.state.ctaLabel,
          ctaUrl: this.state.ctaUrl,
          campaign: this.state.campaign.split('/')[2],
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          country: this.state.country,
          include: this.state.include,
          getAQuote: this.state.getAQuote,
          bookAppointment: this.state.bookAppointment,
          userStatus: this.state.userStatus,
          detailTitle: this.state.detailTitle,
          detailDescription: this.state.detailDescription,
          offerDescription: this.state.offerDescription
        },
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          console.log(response);
          currentComponent.setState({redirection: true});
        });
    } else {
      console.error('Invalid Form')
    }

  }

  getCampaigns() {
    if (this.state.campaigns.length > 0) {
      return (
        <Form.Group controlId="campaign">
          <Form.Label>Campaign</Form.Label>
          <Form.Control as="select" name='campaign' value={this.state.campaign} onChange={this.handleChange}>
            {this.state.campaigns.map((campaign) => (
              <option value={campaign['@id']}>{campaign.title}</option>
            ))}
          </Form.Control>
        </Form.Group>
      );
    }
  }

  render() {
    if (this.state.redirection) {
      return <Redirect to='#/offers'/>;
    }
    return (

      <div className="white-container mb-5 container" >
        <div className="white-container mb-5">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#/offers">
              Offers Creation
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{this.state.name} </Breadcrumb.Item>
          </Breadcrumb>

          <div className="mb-4">
            <Form>
              <Form.Group controlId="country">
                <Form.Label>Country :</Form.Label>
                <Form.Control as="select" name='country' value={this.state.country} onChange={this.handleChange}>
                  {this.state.countries.map((country) => (
                    <option value={country}>{country}</option>
                  ))}
                </Form.Control>


              </Form.Group>

              <h4>Slider</h4>
              <Form.Group controlId="sliderImage">
                <Form.Label>Slider Image :</Form.Label>
                <Form.Control type="file" name='fileInput2' onChange={this.uploadFile}/>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>Offer short title :</Form.Label>
                <Form.Control type="text" name='name' value={this.state.name} onChange={this.handleChange} />
                {this.state.errors.name ? this.state.errors.name.length > 0 &&
                  <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.name}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="offerDescription">
                <Form.Label>Short description :</Form.Label>
                <Form.Control as="textarea" rows="3" name='offerDescription' value={this.state.offerDescription} onChange={this.handleChange} />
                {this.state.errors.offerDescription ? this.state.errors.offerDescription.length > 0 &&
                  <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.offerDescription}
                </span> : ''}
              </Form.Group>

              <h4>Offer details</h4>
              <Form.Group controlId="thumbnail">
                <Form.Label>Offer image :</Form.Label>
                <Form.Control type="file" name='fileInput' onChange={this.uploadFile}/>
              </Form.Group>
              <Form.Group controlId="detailTitle">
                <Form.Label>Offer title :</Form.Label>
                <Form.Control type="text"  name='detailTitle' value={this.state.detailTitle} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="detailDescription">
                <Form.Label>Long description :</Form.Label>
                <Form.Control as="textarea" rows="3" name='detailDescription' value={this.state.detailDescription} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Offer details (Offer of the moment) :</Form.Label>
                <Form.Control as="textarea" rows="3" name='description' value={this.state.description} onChange={this.handleChange} />
                {this.state.errors.description ? this.state.errors.description.length > 0 &&
                  <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.description}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="startDate">
                <Form.Label>Start date :</Form.Label>
                <Form.Control type="date" name='startDate' value={this.state.startDate} onChange={this.handleChange} />
                {this.state.errors.startDate ? this.state.errors.startDate.length > 0 &&
                  <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.startDate}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label>End date :</Form.Label>
                <Form.Control type="date" name='endDate' value={this.state.endDate} onChange={this.handleChange} />
                {this.state.errors.endDate ? this.state.errors.endDate.length > 0 &&
                  <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.endDate}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="include">
                <Form.Check type="checkbox" name='include' checked={this.state.include} label="include" onChange={this.handleCheck}/>
              </Form.Group>
              <Form.Group controlId="getAQuote">
                <Form.Check type="checkbox" name='getAQuote' checked={this.state.getAQuote} label="getAQuote" onChange={this.handleCheck}/>
              </Form.Group>
              <Form.Group controlId="bookAppointment">
                <Form.Check type="checkbox" name='bookAppointment' checked={this.state.bookAppointment} label="bookAppointment" onChange={this.handleCheck}/>
              </Form.Group>
              <Form.Group controlId="userStatus">
                <Form.Label>user Status</Form.Label>
                <Form.Control as="select" name='userStatus' value={this.state.userStatus} onChange={this.handleChange}>
                  <option value="1">1 - Bronze</option>
                  <option value="2">2 - Silver</option>
                  <option value="3">3 - Gold</option>
                  <option value="4">4 - Premium</option>
                </Form.Control>
              </Form.Group>
              {this.getCampaigns()}

              <div className='hide'>
                <Form.Group controlId="ctaLabel">
                  <Form.Label>ctaLabel :</Form.Label>
                  <Form.Control type="text"  name='ctaLabel' value={this.state.ctaLabel} onChange={this.handleChange} />
                </Form.Group>
                <Form.Group controlId="ctaUrl">
                  <Form.Label>ctaUrl :</Form.Label>
                  <Form.Control type="text"  name='ctaUrl' value={this.state.ctaUrl} onChange={this.handleChange} />
                </Form.Group>
              </div>

              <Button onClick={this.handleSubmit} disabled={!this.validateForm(this.state.errors) || Object.keys(this.state.errors).length == 0} variant="danger">
                Send
              </Button>
            </Form>
          </div>
        </div>

      </div>

    )
  }
}

export default OfferCreation

