import React, {useEffect, useState} from "react"
import instance from "../../api/httpclient";
import { Link, Redirect } from  'react-router-dom';
import API_ENTRY_POINT from "../../constants";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Button, Row, Col, Image, Modal, Form} from "react-bootstrap";
import moment from "moment";

const Offer = ({location}) => {

  const [offer, setOffer] = useState({});
  const [campaign, setCampaign] = useState({});
  const [image, setImage] = useState('');
  const [sliderImage, setSliderImage] = useState('');
  const [redirection, setRedirection] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    console.log(location);
    if (location.state) {
      instance.get('/offers/' + location.state.id,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          console.log(response);
          setOffer(response.data);
          instance.get(response.data.campaign,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (rsps) {
              setCampaign(rsps.data);
            });
          instance.get(response.data.thumbnail,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (res) {
              if (undefined !== res.data.contentUrl) {
                setImage(API_ENTRY_POINT + res.data.contentUrl)
              }
              }
            );
          instance.get(response.data.sliderImage,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (res) {
              if (undefined !== res.data.contentUrl) {
                setSliderImage(API_ENTRY_POINT + res.data.contentUrl)
              }
              }
            )
        });
    }
  },[]);

  const deleteOffer = () => {
    instance.delete('/offers/' + location.state.id, {
      headers: {
        'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
      }
    })
    .then(function (response) {
      console.log(response);
      handleClose();
      setRedirection(true);
    });
  };

  const getThumbImage = () => {
    if ('' !== image) {
      return (
        <Image src={image} fluid/>
      )
    }
  };

  const getSliderImage = () => {
    if ('' !== sliderImage && null !== sliderImage && undefined !== sliderImage ) {
      return (
        <Image src={sliderImage} fluid/>
      )
    }
  };

  const getOffer = () => {

    if (offer) {
      if (redirection) {
        //Affichage de la redirection
        return <Redirect to='#/offers'/>;
      }
      return (
        <div className="white-container mb-5">
          <Breadcrumb>
            <Breadcrumb.Item href="#/dashboard">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#/offers">
              Offers
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{offer.name} </Breadcrumb.Item>
          </Breadcrumb>

          <div className="mb-4">
            <h1>{offer.name}</h1>
            <div className="row mb-4">
              <div className="col-md-6">
                {getThumbImage()}
              </div>
              <div className="col-md-6">
                {getSliderImage()}
              </div>
            </div>
            <p><label>Date :</label> {moment(offer.startDate, moment.ISO_8601).format('YYYY-MM-DD')} / {moment(offer.endDate, moment.ISO_8601).format('YYYY-MM-DD')}</p>

            <div className="text-center">
              <div className="card card-full-width">
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Country : </label> <br/>
                        <label>Short description : </label> <br/>
                        <label>ctaLabel : </label> <br/>
                        <label>ctaUrl : </label> <br/>
                        <label>Campaign : </label> <br/>
                      </div>
                      <div className="col-md-8">
                        <div className="mb-2">{offer.country}</div>
                        <div className="mb-2">{offer.description}</div>
                        <div className="mb-2">{offer.ctaLabel}</div>
                        <div className="mb-2">{offer.ctaUrl}</div>
                        <div className="mb-2">{campaign.title}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5">
                        <label>include : </label> <br/>
                        <label>getAQuote : </label> <br/>
                        <label>bookAppointment : </label> <br/>
                        <label>user Status : </label> <br/>
                        <label>Offer title : </label> <br/>
                        <label>Offer description : </label> <br/>
                        <label>Short description :</label> <br/>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">{offer.include === true ? 'true' : 'false'}</div>
                        <div className="mb-2">{offer.getAQuote === true ? 'true' : 'false'}</div>
                        <div className="mb-2">{offer.bookAppointment === true ? 'true' : 'false'}</div>
                        <div className="mb-2">
                          {offer.userStatus === 1 ? ' Bronze' : ''}
                        {offer.userStatus === 2 ? ' Silver' : ''}
                        {offer.userStatus === 3 ? ' Gold' : ''}
                        {offer.userStatus === 4 ? ' Premium' : ''}
                        </div>
                        <div className="mb-2">
                          {offer.detailTitle}
                        </div>
                        <div className="mb-2">
                          {offer.detailDescription}
                        </div>
                        <div className="mb-2">
                          {offer.offerDescription}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row xs={2} md={4} lg={6}>
            <Col>
              <Link to={{
                pathname: "/offer-edit",
                state: { id: offer.id }
              }} className={"MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-217 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"}>
                <svg className="MuiSvgIcon-root RaButton-smallIcon-214" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
                Edit
              </Link>
            </Col>
            <Col>
              <Link to='#' onClick={handleShow} className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-217 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall delete-icon">
                Delete
              </Link>
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Offer</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>The offer will be deleted.</p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
              <Button variant="danger" onClick={deleteOffer}>Delete</Button>
            </Modal.Footer>
          </Modal>
        </div>

      );
    }
  };

  return (


    <div className="white-container mb-5 container">

      { getOffer()}


    </div>

  )
}

export default Offer

