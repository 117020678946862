// in src/Menu.js
import React, {createElement, useState} from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';
import {Button, Form, Modal} from "react-bootstrap";
import instance from "../../api/httpclient";



const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  const [show, setShow] = useState(false);
  const [vin, setVin] = useState(null);
  const { permissions } = usePermissions();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const removeVin = () => {
    instance.post('/vin/free', {
        vin: vin,
    },
{
      headers: {
        'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
      }
    })
    .then(function (response) {
      console.log(response);
      handleClose();
    });
  };

  return (
    <div>

      {permissions ? permissions.indexOf('ROLE_MINDOZA') !== -1 &&
      <MenuItemLink
        to="/dashboard"
        primaryText="Dashboard"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : ''}

      {permissions ? permissions.indexOf('ROLE_MINDOZA') !== -1 &&
      <MenuItemLink
        to="/offers"
        primaryText="Offers"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : ''}

      {/* {permissions ? permissions.indexOf('ROLE_MINDOZA') !== -1 &&
        <MenuItemLink
          to="/customers-journey"
          primaryText="Customer Journey"
          leftIcon={<LabelIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        : ''} */}

      {/* {permissions ? permissions.indexOf('ROLE_ADMIN') !== -1 &&
        <MenuItemLink
          to="/customers-journey"
          primaryText="Customer Journey"
          leftIcon={<LabelIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        : ''} */}

      {permissions ? permissions.indexOf('ROLE_CUSTOMER_SERVICE') !== -1 &&
      <MenuItemLink
        to="/contacts-youplus"
        primaryText="Contacts You+"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : '' }

      {permissions ? permissions.indexOf('ROLE_BASIS') !== -1 &&
      <MenuItemLink
        to="/rewards-list"
        primaryText="Rewards"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : ''}
      {permissions ? permissions.indexOf('ROLE_BASIS') !== -1 &&
      <MenuItemLink
        to="/contacts-basis"
        primaryText="Contacts Basis"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : ''}
      {permissions ? permissions.indexOf('ROLE_MINDOZA') !== -1 &&
      <MenuItemLink
          to='#'
          primaryText="Remove VIN"
          leftIcon={<LabelIcon/>}
          onClick={handleShow}
          sidebarIsOpen={open}
      />
      : ''}


      {/*{resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            resource.name
          }
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}*/}

      {isXSmall && logout}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove VIN</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <div className="row justify-content-md-center align-items-center">
              <div className="col-md-4">
                <Form.Label>Vin to remove :</Form.Label>
                <Form.Control type="text" name='vin'
                              onChange={(event) => {
                                setVin(event.target.value)
                              }}/>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="danger" onClick={removeVin}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Menu;
